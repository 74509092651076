import {
  BooleanParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { TransactionSearchOrder } from '@/globalTypes';
import {
  AdjustmentCategoryParam,
  AdjustmentTypeParam,
  BrandParam,
  PaymentStatusesParam,
  TransactionSearchOrderParam,
  TransactionTypesParam,
} from '@/utils/query/params';

export const queryParams = {
  adjustmentCategory: AdjustmentCategoryParam,
  adjustmentType: AdjustmentTypeParam,
  after: StringParam,
  before: StringParam,
  brand: BrandParam,
  currency: StringParam,
  desc: withDefault(BooleanParam, true),
  first: NumberParam,
  from: DateTimeParam,
  id: StringParam,
  last: NumberParam,
  maxAmount: NumberParam,
  minAmount: NumberParam,
  orderBy: withDefault(
    TransactionSearchOrderParam,
    TransactionSearchOrder.processedAt,
  ),
  paymentMethod: StringParam,
  paymentStatus: PaymentStatusesParam,
  playerId: StringParam,
  rawId: StringParam,
  to: DateTimeParam,
  transactionTypes: TransactionTypesParam,
};
